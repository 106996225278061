import {
  Box,
  Paper,
  Skeleton,
  Typography,
  IconButton,
} from '@mui/material';
import { DateTime } from 'luxon';
import {
  useEffect,
  useRef,
} from 'react';
import { useSnackbar } from 'notistack';
import { marked } from 'marked';
import {
  BiLike,
  BiDislike,
  BiSolidLike,
  BiSolidDislike,
} from 'react-icons/bi';
import { FiRefreshCw } from 'react-icons/fi';
import { LuCopy } from 'react-icons/lu';

export default function MessageList(props) {
  const {
    items,
    loading,
    handleRateThreadResponse,
    handleRetryThreadMessage,
  } = props;
  const scrollTargetRef = useRef();

  useEffect(() => {
    if (items.length > 0 || loading) {
      scrollTargetRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [
    loading,
    items.length,
  ]);

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      pt={2}
      px={2}
      sx={{
        height: '100%',
        overflowY: 'scroll',
        bgcolor: 'background.paper',
      }}
    >
      {items.map((item, index) => (
        <MessageItem
          key={item.id}
          date={item.attributes.createdAt}
          handleRateMessage={handleRateThreadResponse}
          handleRetryMessage={handleRetryThreadMessage}
          isLastMessageItem={(items.length - 1) === index}
          message={item.attributes.content}
          messageId={item.id}
          role={item.attributes.role}
          userRated={item.attributes.userRated}
        />
      ))}
      {loading && <MessageLoadingIndicator />}
      <div ref={scrollTargetRef} id="scroll-target" />
    </Box>
  );
}

function MessageItem(props) {
  const {
    role,
    date,
    message,
    messageId,
    userRated,
    isLastMessageItem,
    handleRateMessage,
    handleRetryMessage,
  } = props;
  const { enqueueSnackbar } = useSnackbar();

  const flexAlign = role === 'user' ? 'flex-end' : 'flex-start';
  const dateDisplay = DateTime
    .fromISO(date)
    .setLocale('de')
    .toLocaleString(DateTime.DATETIME_SHORT);

  const markedMessage = marked(message, {
    smartypants: true,
    breaks: true,
    renderer: CUSTOM_RENDERER,
  });

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(message);
      enqueueSnackbar('Copied to clipboard!', {
        variant: 'success',
      });
    } catch (err) {
      console.error('Copy to clipboard failed:', err.message);
      enqueueSnackbar('Copy to clipboard failed', {
        variant: 'error',
      });
    }
  };

  return (
    <Box
      alignSelf={flexAlign}
      sx={MESSAGE_BOX_STYLES}
    >
      <Paper
        sx={role === 'user' ? MESSAGE_BOX_STYLES_USER : MESSAGE_BOX_STYLES_ASSISTANT}
      >
        <Typography
          color={role === 'user' ? 'primary.contrastText' : 'text.primary'}
          component="div"
          dangerouslySetInnerHTML={{ __html: markedMessage }}
          sx={{
            wordBreak: 'break-word',
            '& p': {
              my: 0,
            },
          }}
          variant="body2"
        />
      </Paper>
      <Box
        display="flex"
        justifyContent="space-between"
        mt={0.5}
        px={2}
      >
        {role === 'assistant' && isLastMessageItem && handleRateMessage && handleRetryMessage && (
          <Box>
            <IconButton
              onClick={handleCopy}
              size="small"
            >
              <LuCopy />
            </IconButton>
            <IconButton
              onClick={() => {
                if (userRated !== true) {
                  handleRateMessage(messageId, true);
                }
              }}
              size="small"
            >
              {userRated === true ? <BiSolidLike /> : <BiLike /> }
            </IconButton>
            <IconButton
              onClick={() => {
                if (userRated !== false) {
                  handleRateMessage(messageId, false);
                }
              }}
              size="small"
            >
              {userRated === false ? <BiSolidDislike /> : <BiDislike /> }
            </IconButton>
            <IconButton
              onClick={() => handleRetryMessage(messageId)}
              size="small"
            >
              <FiRefreshCw />
            </IconButton>
          </Box>
        )}
        <Typography
          color="text.disabled"
          component="div"
          variant="caption"
        >
          {dateDisplay}
        </Typography>
      </Box>
    </Box>
  );
}

function MessageLoadingIndicator() {
  return (
    <Box sx={MESSAGE_BOX_STYLES}>
      <Skeleton
        height={52}
        variant="rounded"
      />
    </Box>
  );
}

const CUSTOM_RENDERER = new marked.Renderer();

const MESSAGE_BOX_STYLES = {
  maxWidth: { xs: '90%', md: '80%' },
  mb: 2,
};

const MESSAGE_BOX_STYLES_ASSISTANT = {
  p: 2,
  bgcolor: 'grey.200',
  borderBottomLeftRadius: 0,
};

const MESSAGE_BOX_STYLES_USER = {
  p: 2,
  bgcolor: 'primary.main',
  borderBottomRightRadius: 0,
};
