import {
  Box,
  Divider,
} from '@mui/material';
import {
  useCallback,
  useEffect,
  useState,
  useRef,
} from 'react';
import {
  gql,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import Select from '@components/form/Select';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import MessageList from './MessageList';
import MessageTextBox from './MessageTextBox';

export default function Chat() {
  const router = useRouter();
  const { t, i18n } = useTranslation();
  const firstRender = useRef(true);
  const [threadId, setThreadId] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const { data: initialMessagesData } = useQuery(FETCH_INITIAL_MESSAGES, {
    variables: {
      threadId,
    },
  });
  const [fetchThreadMessages, { data }] = useLazyQuery(FETCH_THREAD_MESSAGE, {
    pollInterval: 1000,
  });
  const [createThread] = useMutation(CREATE_THREAD);
  const [createMessage, { loading }] = useMutation(CREATE_MESSAGE);
  const [rateThreadResponse] = useMutation(RATE_THREAD_RESPONSE);
  const [retryThreadMessage, { loading: retryLoading }] = useMutation(RETRY_THREAD_MESSAGE);

  const eventId = router.query.eventid;
  const threads = initialMessagesData?.assistantThreads.data ?? [];
  const messages = data?.messages.data?.length > 0
    ? data.messages.data
    : initialMessagesData?.assistantInitialMessages.data ?? [];

  useEffect(() => {
    if (firstRender.current) {
      const existingThreadId = initialMessagesData?.assistantThread.data?.id;

      if (existingThreadId) {
        firstRender.current = false;
        setThreadId(existingThreadId);
        fetchThreadMessages({ variables: { threadId: existingThreadId } });
      }
    }
  }, [
    setThreadId,
    initialMessagesData,
    fetchThreadMessages,
  ]);

  const handleSubmitMessage = useCallback(async (value) => {
    try {
      let localThreadId = threadId;

      if (!localThreadId) {
        // Create thread first & then post the message
        const { data: threadData2 } = await createThread({
          variables: {
            data: {
              event: eventId,
              content: value,
            },
          },
        });
        localThreadId = threadData2.assistantCreateThread.data.id;
        setThreadId(localThreadId);
      } else {
        await createMessage({
          variables: {
            threadId: localThreadId,
            data: {
              content: value,
            },
          },
        });
      }
      await fetchThreadMessages({ variables: { threadId: localThreadId } });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }, [
    eventId,
    threadId,
    createMessage,
    createThread,
    enqueueSnackbar,
    fetchThreadMessages,
  ]);

  const handleRateThreadResponse = useCallback(async (messageId, positive) => {
    try {
      await rateThreadResponse({
        variables: {
          threadId,
          messageId,
          positive,
        },
      });
      enqueueSnackbar(positive ? 'Liked' : 'Disliked', {
        variant: 'success',
      });
      await fetchThreadMessages({ variables: { threadId } });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }, [
    threadId,
    rateThreadResponse,
    enqueueSnackbar,
    fetchThreadMessages,
  ]);

  const handleRetryThreadMessage = useCallback(async (messageId) => {
    try {
      await retryThreadMessage({
        variables: {
          threadId,
          messageId,
        },
      });
      enqueueSnackbar('Retried', {
        variant: 'success',
      });
      await fetchThreadMessages({ variables: { threadId } });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }, [
    threadId,
    retryThreadMessage,
    enqueueSnackbar,
    fetchThreadMessages,
  ]);

  const startNewChat = async () => {
    setThreadId(null);
    await fetchThreadMessages({ variables: { threadId: null } });
  };

  const handleChangeThread = async (event) => {
    setThreadId(event.target.value);
    await fetchThreadMessages({ variables: { threadId: event.target.value } });
  };

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      sx={{
        overflowY: 'hidden',
      }}
    >
      <Box
        pt={2}
        px={2}
      >
        <Select
          fullWidth
          items={[
            {
              id: null,
              name: t('components.Chat.startNewChat'),
            },
            ...threads.map((thread) => ({
              id: thread.id,
              name: DateTime.fromISO(thread.attributes.createdAt).setLocale(i18n.language)
                .toLocaleString(DateTime.DATETIME_SHORT),
            })),
          ]}
          label={t('components.Chat.previousMessageThreads')}
          labelProp="name"
          onChange={handleChangeThread}
          size="small"
          value={threadId ?? ''}
        />
      </Box>
      <MessageList
        handleRateThreadResponse={handleRateThreadResponse}
        handleRetryThreadMessage={handleRetryThreadMessage}
        items={messages}
        loading={loading || retryLoading}
      />
      <Box>
        <Divider />
        <Box p={1}>
          <MessageTextBox
            disabled={loading}
            onSubmit={handleSubmitMessage}
            startNewChat={startNewChat}
          />
        </Box>
      </Box>
    </Box>
  );
}

const FETCH_INITIAL_MESSAGES = gql`
  query FetchInitialMessages($threadId: ID) {
    assistantInitialMessages {
      data {
        id
        attributes {
          role
          content
          createdAt
        }
      }
    }
    assistantThreads {
      data {
        id
        attributes {
          createdAt
        }
      }
    }
    assistantThread(threadId: $threadId) {
      data {
        id
      }
    }
  }
`;

const FETCH_THREAD_MESSAGE = gql`
  query FetchThreadMessages($threadId: ID) {
    messages (
      sort: ["createdAt:asc"]
      filters: {
        thread: {id: {eq: $threadId}},
        isHidden: {eq: false}
      }
    ) {
      data {
        id
        attributes {
          role
          content
          createdAt
          userRated
        }
      }
    }
  }
`;

const CREATE_THREAD = gql`
  mutation CreateThread($data: CreateThreadInput!) {
    assistantCreateThread(data: $data) {
      data {
        id
      }
    }
  }
`;

const CREATE_MESSAGE = gql`
  mutation CreateMessage($threadId: ID!, $data: CreateMessageInput!) {
    assistantCreateMessage(threadId: $threadId, data: $data)
  }
`;

const RATE_THREAD_RESPONSE = gql`
  mutation RateThreadResponse($threadId: ID!, $messageId: ID!, $positive: Boolean!) {
    assistantRateThreadResponse(threadId: $threadId, messageId: $messageId, positive: $positive)
  }
`;

const RETRY_THREAD_MESSAGE = gql`
  mutation RetryThreadMessage($threadId: ID!, $messageId: ID!) {
    assistantThreadMessageRetry(threadId: $threadId, messageId: $messageId)
  }
`;
